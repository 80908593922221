
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '@/types/common'
import { OrderDetail, OrderHandleInfo } from '@/types/workOrder.d'
import UploadFile from '@/components/uploadFile/Index.vue'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import LocationImg from '@/assets/img/icon_address.png'

@Component({
  components: { UploadFile },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: FileInfo) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    }
  }
})
export default class extends Vue {
  @Prop({ default: false }) private detailShow!: boolean
  @Prop({ default: false }) private isMyOrder!: boolean
  @Prop({ default: '' }) private orderId!: string
  @Prop({ default: '' }) private handleType!: string
  @Prop({ default: true }) private showLocation!: boolean

  private activeName = 'orderData'
  private detail: OrderDetail = {
    projectId: '',
    orderType: '',
    orderTitle: '',
    orderContent: '',
    orderDate: '',
    address: '',
    fileList: [],
    orderUserId: '',
    flowList: []
  }

  private info: OrderHandleInfo = {
    remark: '',
    userId: '',
    fileList: [],
    result: '',
    receiveUserId: ''
  }

  private imgList = ['jpeg', 'jpg', 'png']
  private videoList = ['mp4', 'mov']
  private typeList = [
    {
      value: '反馈',
      color: '#F5A623'
    },
    {
      value: '审核',
      color: '#D0021B'
    },
    {
      value: '转办',
      color: '#00B8AF'
    },
    {
      value: '关闭',
      color: '#9B9B9B'
    }
  ]

  private handleButtonShow = false
  private handleDialogType = '0'
  private submitShow = false
  private feedbackList = []
  private reviewerList = []
  private map: any = null
  private marker: Array<any> = []
  private addressShow = false
  private scrollview: any = null
  private orderStatusList = [
    {
      name: '待反馈',
      color: '#F5A623'
    },
    {
      name: '待审核',
      color: '#F5A623'
    },
    {
      name: '已完成',
      color: '#00B8AF'
    },
    {
      name: '已关闭',
      color: '#9B9B9B'
    }
  ]

  private rules= {
    remark: [
      { required: true, message: '请输入说明', trigger: ['blur', 'change'] }
    ],
    result: [
      { required: true, message: '请选择审核结果', trigger: ['blur', 'change'] }
    ],
    receiveUserId: [
      { required: true, message: '请选择处理人', trigger: ['blur', 'change'] }
    ],
    userId: [
      { required: true, message: '请选择处理人', trigger: ['blur', 'change'] }
    ]
  }

  get userInfo () {
    return JSON.parse(sessionStorage.getItem('userInfo') as string)
  }

  created () {
    this.handleDialogType = this.handleType
    this.handleButtonShow = this.handleType !== '4'
    this.getDetail()
  }

  // 滚动tab切换
  scrollChange () {
    const anchor = document.getElementById('flowPath')
    const orderDetailHeight = document.getElementById('orderDetail')
    if (anchor && orderDetailHeight) {
      if (orderDetailHeight.scrollTop > anchor.offsetTop) {
        this.activeName = 'flowPath'
      } else {
        this.activeName = 'orderData'
      }
    }
  }

  getDetail () {
    this.$axios.get(this.$apis.workOrder.selectWorkOrderByOrderId, {
      orderId: this.orderId
    }).then(res => {
      this.detail = res || {}
    }).finally(() => {
      this.getFeedbackList()
      this.getReviewerList()
    })
  }

  // 反馈候选人
  getFeedbackList () {
    this.$axios.get(this.$apis.workOrder.selectOrderTypeFeedbackUserByList, {
      orderType: this.detail.orderType,
      projectId: this.detail.projectId
    }).then(res => {
      this.feedbackList = res || []
    })
  }

  // 审核候选人
  getReviewerList () {
    this.$axios.get(this.$apis.workOrder.selectOrderTypeApproveUserByList, {
      orderType: this.detail.orderType,
      projectId: this.detail.projectId
    }).then(res => {
      this.reviewerList = res || []
    })
  }

  // 转办下拉列表
  getTransferList () {
    if (this.detail.orderStatus === '0') {
      return this.feedbackList
    } else if (this.detail.orderStatus === '1') {
      return this.reviewerList
    }
  }

  // 获取工单状态名/颜色
  getStatus (status: string) {
    return this.orderStatusList[Number(status)] || {}
  }

  // 地图定位
  onAddressShow () {
    this.addressShow = true
    const lng = Number(this.detail.longitude)
    const lat = Number(this.detail.latitude)
    this.$nextTick(() => {
      this.initMap(lng, lat)
    })
  }

  // 初始化地图
  initMap (lng: number, lat: number) {
    if (this.map) {
      this.map.setCenter([lng, lat])
    } else {
      AMapLoader.load({
        key: mapKey,
        version: '2.0',
        plugins: [
          'AMap.MouseTool'
        ]
      }).then((AMap) => {
        this.map = new AMap.Map('map', {
          center: [lng, lat],
          zoom: 15
        })
        this.addMarker(lng, lat)
      })
    }
  }

  addMarker (lng: number, lat: number) {
    this.map.remove(this.marker)
    if (lng && lat) {
      this.marker = []
      const marker: any = new AMap.Marker({
        map: this.map,
        position: new AMap.LngLat(lng, lat),
        offset: new AMap.Pixel(-20, -10),
        icon: LocationImg
      })
      marker.setLabel({
        direction: 'top',
        offset: new AMap.Pixel(0, -5),
        content: `<div class="addressMarker">
                    <span>${this.detail.address}</span>
                  </div>`
      })
      this.marker.push(marker)
      this.map.add(marker)
    }
  }

  // 定位到办理流程
  changeTab () {
    const anchor = document.getElementById(this.activeName)
    anchor && anchor.scrollIntoView({ behavior: 'smooth' })
  }

  // 处理类型 4：查看 0：反馈 1：审核 2：转办 3：关闭
  toHandle (type: string) {
    if (type) {
      this.handleDialogType = type
    } else {
      this.handleDialogType = this.detail.orderStatus || ''
    }
    this.handleButtonShow = true
  }

  imgRemove (index: number) {
    this.info.fileList.splice(index, 1)
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.fileList.push(file)
    this.$nextTick(() => {
      (this.$refs.form as ElForm).clearValidate()
    })
  }

  radioChange (status: string) {
    this.info.result = status
  }

  toSubmit () {
    (this.$refs.form as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        const urlList = this.isMyOrder ? [
          this.$apis.workOrder.insertThisWorkOrderFlowTwo,
          this.$apis.workOrder.insertThisWorkOrderFlowTwo,
          this.$apis.workOrder.insertThisWorkOrderFlowOwn,
          this.$apis.workOrder.insertThisWorkOrderFlowClose
        ] : [
          this.$apis.workOrder.insertWorkOrderFlowTwo,
          this.$apis.workOrder.insertWorkOrderFlowTwo,
          this.$apis.workOrder.insertYhWoPass,
          this.$apis.workOrder.insertWorkOrderFlowClose
        ]
        const txtList = ['反馈', '审核', '转办', '关闭']
        const flowType = this.handleDialogType === '0' ? '1' : this.handleDialogType === '1' ? '2' : ''
        this.$axios.post(urlList[Number(this.handleDialogType)], {
          ...this.info,
          orderId: this.detail.orderId,
          flowType: flowType
        }).then(() => {
          this.$message.success(txtList[Number(this.handleDialogType)] + '成功')
          this.getDetail()
          this.handleButtonShow = false
          this.clearInfo()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  // 计算弹窗下边距
  getMarginBottom () {
    const pxList = [405, 335, 285, 235]
    if (this.handleButtonShow) {
      return this.info.fileList.length > 4 ? pxList[Number(this.handleDialogType)] + 105 : pxList[Number(this.handleDialogType)]
    } else {
      return (this.detail.orderStatus === '2' || this.detail.orderStatus === '3') ? 0 : 80
    }
  }

  toClose () {
    this.handleButtonShow = false
    this.handleDialogType = '4'
    this.clearInfo()
  }

  // 关闭弹窗
  handleClose () {
    this.$emit('closeDialog')
    this.clearInfo()
  }

  clearInfo () {
    this.info = {
      remark: '',
      userId: '',
      fileList: [],
      result: '',
      receiveUserId: ''
    }
  }
}
